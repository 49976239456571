<i18n locale="th" lang="yaml" >
page.title : "Pending Tickets"
page.description : "รายการของ Ticket ที่รอข้อมูลเพิ่มเติม ซึ่งอาจเป็นเพราะหาสาเหตุหลักยังไม่พบ หรือ ข้อมูลไม่เพียงพอ"
</i18n>
<template>
	<div class="page">
		<my-page-header
		:title="$t('page.title',{ name: company.name })"
		:description="$t('page.description',{ name: company.name })"/>

		<a-card :bordered="false">
			<TicketTableMode
				mode="pending"
				filter-name="report_by_company_id"
				:filter-value="$route.params.id"
				:back-link="{ name: 'company/tickets',params: { id: $route.params.id } }"/>
		</a-card>
	</div>

</template>

<script>
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
export default {
	components : {
		TicketTableMode,
	} ,
	props : {
		company : {
			type : Object,
			default : () => []
		}
	}
}
</script>
